import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import PostList from '../components/Blog/PostList';
import Layout from '../components/Layout';

interface Props {
    data: {
        allMarkdownRemark: {
            edges: any;
        };
        site: {
            siteMetadata: {
                title: string;
                description: string;
            };
        };
    };
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { draft: { eq: false } } }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMM D, YYYY")
                        title
                    }
                }
            }
        }
    }
`;

const Page = ({ data }: Props) => {
    const posts = data.allMarkdownRemark.edges;
    const { siteMetadata } = data.site;
    const siteTitle = siteMetadata.title;
    const siteDescription = siteMetadata.description;
    return (
        <Layout>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                meta={[{ name: 'description', content: siteDescription }]}
                title={siteTitle + ' | Blog'}
            />
            <PostList pageTitle="Posts" posts={posts} />
        </Layout>
    );
};

export default Page;
